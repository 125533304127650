import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  latitude: {
    name: t('positionLatitude'),
    type: 'number',
    property: true,
  },
  longitude: {
    name: t('positionLongitude'),
    type: 'number',
    property: true,
  },
  speed: {
    name: t('positionSpeed'),
    type: 'number',
    dataType: 'speed',
    property: true,
  },
  course: {
    name: t('positionCourse'),
    type: 'number',
    property: true,
  },
  address: {
    name: t('positionAddress'),
    type: 'string',
    property: true,
  },
  deviceTime: {
    name: t('positionDeviceTime'),
    type: 'string',
    property: true,
  },
  serverTime: {
    name: t('positionServerTime'),
    type: 'string',
    property: true,
  },
  hours: {
    name: t('positionHours'),
    type: 'number',
    dataType: 'hours',
  },
  ignition: {
    name: t('positionIgnition'),
    type: 'boolean',
  },
  totalDistance: {
    name: t('deviceTotalDistance'),
    type: 'number',
    dataType: 'distance',
  },
  motion: {
    name: t('positionMotion'),
    type: 'boolean',
  },
}), [t]);
